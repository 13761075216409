import React, {useState} from 'react';
import {Modal} from '@material-ui/core';
import AgentResetPasswordForm from '../agentResetPasswordForm';
import {makeStyles} from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 400,
    overflow: 'auto',
  },
  FloatRight: {
    float: 'right',
  },
}));

function AgentResetPasswordModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={props.open}
      aria-labelledby='title'
      aria-describedby='description'
      onClose={(e) => (e) => props.handleOpen(false)}>
      <div style={modalStyle} className={classes.paper}>
        <CloseRoundedIcon
          className={classes.FloatRight}
          onClick={(e) => props.handleOpen(false)}
        />
        <AgentResetPasswordForm />
      </div>
    </Modal>
  );
}
export default AgentResetPasswordModal;
